.page {
    padding-top: 160px;
    @media(min-width: $large) {
        padding-top: 120px;
    }
}

$opacity: 1050ms;

.animated-header {
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: 500ms;
    z-index: 2;
}

.animated-header, .header-status-enter, .animated-header.activo {
    opacity: 0;
}

.header-status-enter-active {
    transition: 750ms;
    opacity: 1;
}

.header-status-enter-done {
    opacity: 1;
}

.header-status-exit {
    opacity: 1;
}

.header-status-exit-active {
    opacity: 0;
}

.header-status-exit-done {
    opacity: 0;
}

.pagewrap, .fade-enter-done, .page {
    transition: opacity 550ms ease-in-out;
    &.activo {
        opacity: 0;
    }
}
.fade-appear,
.fade-enter {
    opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
    opacity: 1;
    transition: opacity $opacity ease-in-out;
}

.fade-exit {
    opacity: 1;
    transition: opacity $opacity ease-in-out;
}

.fade-exit-active {
    opacity: 0;
}

.result-loading-wrap {
    padding-top: 0;
    height: 90vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    
}
.result-loading {
    transform: translateX(-55%) translateY(-40%) scale(2.5);
    @media (min-width: $medium) {
        transform: translateX(-35%) translateY(-20%) scale(1.5);
    }
    @media (min-width: $xl) {
        transform: translateX(-25%) translateY(-10%) scale(1);
        width: 100%;
    }
}