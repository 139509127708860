.c-footer {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: #3A3A3A;
}

.c-footer__inner {
    // @media(min-width: $medium) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    // }
}

.c-footer__col {
    overflow: hidden;
    margin-bottom: 30px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    &:first-child {
        svg {
            margin-right: 20px;
        }
        > div {
            flex: 0 0 70%;
        }
    }
    a {
        &:visited, & {
            color: #fff !important;
        }
        &:hover {
            text-decoration: none;
        }
    }
    p {
        max-width: 350px;
        margin: 0;
        color: #fff !important;
    }
    @media(min-width: 830px) {
        flex: 0 0 40%;
    }
    &:last-child {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 100%;
        @media(min-width: 830px) {
            flex: 0 0 60%;
        }
    }
    
}

.c-meta-link {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    &:visited, & {
        color: #fff;
        text-decoration: underline;
    }
    &:hover, &:active {
        text-decoration: none;
    }
    @media(min-width: $large) {
        margin-bottom: 0;
    }
}


.footer-subscribe-wrapper {
    // margin: 30px auto 50px;    
    max-width: 1120px;
}

.footer-subscribe {
    padding: 40px 0 60px;
    max-width: 620px;
    color: #fff;
    h2 {
        margin: 0;
        font-size: 24px;
        color: #fff !important;
        @media(min-width: $medium) {
            font-size: 36px;
            
        }
    }
    p {
        margin: .5em 0;
        max-width: 340px;
        font-size: 18px;
        color: #fff !important;
    }

    .footer-subscribe-error, .footer-subscribe-success {
        color: #f03d3d;
        font-size: 18px;
        display: block;
        margin: 0 0 .75em;
        z-index: 3;
        position: relative;
    }

    .footer-subscribe-success { 
        background-color: #64BC5A;
        color: #FFF; 
        margin: .75em 0;
        padding: .75em 0.5em 0.75em 1em;
        display: inline-flex;
        align-items: center;
        border-radius: 0;
        font-size: 22px;
        svg {
            width: 24px;
            height: auto;
            margin-right: .5em;
            path {
                fill: #FFF;
            }
        }
    }

    .email-input {
        margin: 2em 0 0;
        max-width: 520px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: -6px;
            left: -27px;
            width: 100%;
            height: 100%;
            border: 1px solid #545454;
            background-color: #545454;
            display: block;
            transform: rotate(-2deg);
        }
    }
    .has-error .email-input:after {
        border-color: #f03d3d;
    }
    input[type="email"] {
        margin: 0 0 1em;
        margin-left: -1.5em;
        border-radius: 0;
        font-size: 18px;
        border: 1px solid transparent;
        background-color: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        height: 80px;
        padding: 0 25px;
        font-family: $font;
        color: #FFF;
        outline: 0;
        position: relative;
        z-index: 1;
        &::placeholder { color: rgba(#FFF, .75); }
    }

    button {
        position: absolute;
        top: -1px;
        right: 40px;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        display: block;
        height: 80px;
        width: 77px;
        padding: 0;
        box-shadow: none;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: $transition;
        background-color: transparent;
        z-index: 2;
        filter: drop-shadow(8px 6px 0 rgba(0, 0, 0, 0.1));
        span {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            height: 80px;
            width: 77px;
            display: block;
            clip-path: polygon(0 6%, 98% 0, 98% 97%, 0% 100%);
            background-repeat: no-repeat;
            background-image: url('/media/images/ic-arrow-right.svg');
            background-position: center;
        }
        
        &:hover {
            background-color: transparent;
            filter: drop-shadow(6px 4px 0 rgba(0, 0, 0, 0.1));
        }
        &:disabled {
            // opacity: .5;
            cursor: not-allowed;
        }
        svg {
            margin: 0;
            width: 35px;
            height: auto;
            transition: 250ms;
            path {
                fill: $contrast;
            }
        }
        &:hover {
            svg { transform: translateX(7px); }
        }
    }

    .input-check {
        input { position: absolute; pointer-events: none; opacity: 0; z-index: -1;}
        input:checked {
            & + label:after {
                transform: scale(1);
                opacity: 1;
            }
        }
        label {
            display: flex;
            position: relative;
            font-size: 14px;
            cursor: pointer;
            &:before, &:after {
                // border-radius: 50%;
                content: '';
                display: block;
            }
            &:before {
                flex: 0 0 20px;
                width: 20px;
                height: 20px;
                background-color: #FFF;
                border: 1px solid #FFF;
                margin: 0 .75em 0 0;
            }
            &:after {
                position: absolute;
                top: 4px;
                left: 4px;
                background-color: #000;
                width: 12px;
                height: 12px;
                transform: scale(0);
                transition: 250ms;
            }
            &:hover:after {
                transform: scale(1);
                opacity: .75;
            }
        }
        
    }
}
.newsletterLink { padding-left: 0.25em;}