.partners {
    &__list {
        list-style: none;
        padding: 0;
        margin: 0 4px;
        display: flex;
        flex-wrap: wrap;
        overflow: visible;
        li {
            margin: 4px;
            a {
                display: block;
                img {
                    display: block;
                    transition: $transition;
                    max-width: 80px;
                    @media (min-width: $large) {
                        max-width: none;
                    }
                }
                &:hover {
                    img { transform: rotate(3deg);}
                }
            }
        }
    }
}
.partners--popup {
    li {
        @media (min-width: $medium) { flex: 0 0 20%; }
    }
}