.u-primary {
    color: $primary;
}
.u-center {
    text-align: center;
}
.underline {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none;;
    }
}