$primary: #D10A10;
$primary-color: #29C7A1;
$secondary-color: #EC79B6;
$contrast: #000000;

$font: 'Manrope', Arial, sans-serif;
$font-alt: 'Manrope', Arial, sans-serif;

$transition: 0.25s cubic-bezier(0.43, 0.195, 0.02, 1);
$transition-slower: 0.5s cubic-bezier(0.43, 0.195, 0.02, 1);

$xs: 480px;
$small: 640px;
$medium: 768px;
$large: 992px;
$xl: 1280px;
$xxl: 1442px;

// $color-vvd: #0b1971;
// $color-pvv: #00365b;
// $color-cda: #007B5F;
// $color-d66: #01AF40;
// $color-groenlinks: #50C400;
// $color-sp: #FE0000;
// $color-pvda: #E3001A;
// $color-cu: #00A7EB;
// $color-pvdd: #006C2E;
// $color-50plus: #932092;
// $color-sgp: #f56f02;
// $color-denk: #42bac1;
// $color-fvd: #911815;