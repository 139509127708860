.c-result-mail-btn {
    display: none;
    line-height: 1.3;
    span {
        flex: 1;
    }
    .c-result-body & {
        @media(min-width: 1125px) {
            display: inline-flex;
            width: 100%;
        }
    }
}

.c-mobile-mail {
    .c-result-mail-btn {
        display: flex;
        margin: 20px 0 50px;
        max-width: 428px;
        @media(min-width: 1125px) {
            display: none;
        }
    }
}

.c-newsletter-terms-wrapper {
    margin: 20px 0;
}

.c-newsletter-terms-text {
    flex: 1;
}

.c-newsletter-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    max-width: 300px;
    margin-top: 22px;
}

.c-newsletter-popup {
    .c-btn {
        font-size: 20px;
        margin-top: 20px;
    }
}

.c-newsletter-desc {
    border-bottom: 1px solid #ddd;
    display: block;
    padding: 9px 0;
    font-size: 17px;
    margin-bottom: 9px;
}

.c-newsletter-hidden-checkbox {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    height: 0;
    
}