.c-main-title {
    line-height: 1;
    margin: 2rem 0 0.75rem;
    font-size: 38px;
    max-width: 940px;
    // word-break: break-word;
    @media(min-width: $small) {
        font-size: clamp(50px, 10vw, 80px);
    }
    @media(min-width: $xxl) {
        font-size: clamp(50px, 10vw, 90px);
    }
    @media (min-width: $medium) {
        margin: 3rem 0 1.2rem;
    }
    &--alt {
        font-size: 34px;
        word-break: break-word;
        @media(min-width: $small) {
            font-size: clamp(50px, 10vw, 80px);
        }
        @media(min-width: $xxl) {
            font-size: clamp(50px, 10vw, 90px);
        }
    }
}

.c-title-sub {
    font-family: $font-alt;
    font-size: 25px;
    line-height: 1.3;
    margin: 15px 0;
    @media(min-width: 800px) {
        font-size: 32px;
    }
}

.c-intro {
    font-size: 14px;
    color: $contrast;
    @media(min-width: $medium) {
        font-size: 18px;
        margin: 32px 0;
        max-width: 440px;
    }
    &--alt {
        margin: -1rem 0 1.5rem;
        @media(min-width: $medium) {
            margin: 15px 0 40px;
            font-size: 18px;
        }
    }
    &--result {
        font-weight: 400;
        margin-bottom: 30px;
        margin-top: 15px;
    }
    &--wide {
        max-width: none;
    }
}

.c-meta-link {
    color: $contrast;
    font-size: 13px;
    text-decoration: none;
    display: block;
    &:hover {
        text-decoration: underline;
    }
}

h1 {
    font-size: 38px;
    line-height: 1.0555;
    @media(min-width: 800px) {
        font-size: 54px;
    }
}

