.header {
    &__inner {
        padding-top: 20px;
        @media(min-width: $medium) {
            padding-top: 40px;
            display: flex;
        }
    }
    &__visual {
        max-width: 130px;
        position: absolute;
        top: 0;
        right: 20px;
        // @media(min-width: $medium) {
        //     max-width: 200px;
        // }
        @media(min-width: $large) {
            max-width: 264px;
            right: 0;
        }
    }
}


.c-logo {
    padding-top: 5px;
    display: block;
    text-decoration: none;
    
    transition: $transition;
   &:hover {
       opacity: 0.8;
   }
    @media(min-width: $medium) {
        display: flex;
        position: absolute;
        left: 120px;
    }
    svg {
        display: block;
        width: 160px;
        height: auto;
        @media(min-width: 740px) {
            align-self: flex-start;
            width: 200px;
            margin-right: 12px;
            
        }
    }
}
