.c-btn {
    background-color: #fff;
    color: $contrast;
    text-decoration: none;
    font-size: 22px;
    font-weight: 600;
    padding: 0.8em 2em;
    display: inline-flex;
    align-items: center;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: $transition;
    box-shadow: 12px 10px 0 rgba(0, 0, 0, 0.1);
    transform: rotate(-4deg);
    font-family: $font-alt;
    &:hover {
        background-color: #fff;
        box-shadow: 8px 6px 0 rgba(0, 0, 0, 0.1);
    }
    span {
        transform: rotate(4deg);
    }
    
    @media(min-width: $medium) {
        font-size: 26px;
    }
}
.c-btn-home {
    position: relative;
}

select {
    font-size: 16px;
    font-weight: 600;
    padding-right: 2.5em;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-image: url('/media/images/ic-chevron-down-dark.svg');
    background-position: calc(100% - 0.5em) center;
    @media(min-width: $medium) {
        padding-right: 3em;
        background-position: calc(100% - 1em) center;
    }
}
.c-btn-holder {
    display: flex;
    justify-content: center;
    pointer-events: none;
    @media(min-width: $large) {
        justify-content: flex-end;
    }
    &--home {
        margin-top: 30px;
        margin-bottom: 80px;
        position: relative;
        justify-content: flex-start;
        @media(min-width: $large) {
            margin-bottom: 110px;
        }
    }
    &--footer {
        margin-top: -70px;
        margin-bottom: 40px;
        
    }
    .c-btn {
        pointer-events: all;
    }
    .c-about & {
        margin-bottom: 70px;
        margin-top: 50px;
    }
}
.c-btn--arrow {
    margin-top: 10px;
    @media(min-width: $xl) {
        position: fixed;
        bottom: 55px;
        left: auto;
        right: 7vw;
        transform: rotate(-4deg);
    }
}
.c-btn--fixed, .c-btn--arrow {
    margin: 0;
    z-index: 100;
    span {
        display: flex;
        align-items: center;
        &:after {
            content: url('/media/images/ic-arrow-right.svg');
            margin-left: 4px;
            margin-top: 6px;
            transform: scale(0.7);
            @media(min-width: $medium) {
                transform: scale(1);
                margin-left: 10px;
            }
        }
    }
    font-size: 18px;
    @media(min-width: $medium) {
        font-size: 26px;
    }
  
}

.c-btn--fixed {
    position: fixed;
    bottom: 25px; 
    left: 50%;
    transform: translateX(-50%) rotate(-4deg);
    z-index: 100;
   
    @media(min-width: $large) {
        bottom: 55px;
        left: auto;
        right: 7vw;
        transform: rotate(-4deg);
    }
}

.c-btn--themes {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: 1050ms;
    &.activo {
        z-index: 100;
        opacity: 1;
        pointer-events: all;
    }
}

.c-btn-add {
    background-color: transparent;
    text-decoration: underline;
    font-size: 18px;
    padding: 0.5em 0;
    margin: 12px 0;
    display: inline-block;
    font-weight: 400;
    border: 0;
    outline: none;
    cursor: pointer;
    color: $contrast;
    &:hover {
        background-color: transparent;
        color: $contrast;
    }
}

.c-back {
    display: block;
    height: 60px;
    width: 57px;
    padding: 0;
    box-shadow: none;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: $transition;
    background-color: transparent;
    filter: drop-shadow(8px 6px 0 rgba(0, 0, 0, 0.1));
    margin-bottom: 20px;
    position: relative;
    @media(min-width: 975px) {
        position: absolute;
    }
    span {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        height: 60px;
        width: 57px;
        display: block;
        clip-path: polygon(0 6%, 98% 0, 98% 97%, 0% 100%);
        background-repeat: no-repeat;
        background-image: url('/media/images/ic-arrow-left.svg');
        background-position: center;
    }
    
    &:hover {
        background-color: transparent;
        filter: drop-shadow(6px 4px 0 rgba(0, 0, 0, 0.1));
    }
}

.c-result-body {
    .c-btn--back {
        float: right;
    }
}

.c-btn--small {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    margin-top: 70px;
    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/media/images/ic-mail.svg');
        margin: 0 15px 0 -10px;
    }
}