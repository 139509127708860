.c-grid {
    @media(min-width: 800px) {
        display: flex;
        justify-content: space-between;
    }
}

.c-holder-wrapper {
    overflow: hidden;
    width: 100%;
    //Positioning
    position: absolute;
    right: 0;
    top: 0;
    max-width: 207px;
    //End positioning
    &:before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
        padding-bottom: 110%;
        display: table;
	    clear: both;
	}
    @media(min-width: 500px) {
        max-width: 300px;
    }
    @media(min-width: 800px) {
        max-width: 400px;
    }
    @media(min-width: 980px) {
        max-width: 500px;
    }
}

.c-holder {
    width: 100%;    
    pointer-events: none;
    overflow: hidden;
    transition: .35s ease-out;
    background-color: $primary;
    background: linear-gradient(to top, rgba($primary,0.5) 0%, rgba($primary,0.8) 40%,  $primary 100%);
    border-radius: 0 0 99rem 99rem;
    position: relative;
    transform: translateX(42%) translateY(0);
    &:before {
		content: "";
		width: 1px;
		margin-left: -1px;
		float: left;
		height: 0;
        padding-bottom: 130%;
        display: table;
        clear: both;
        @media(min-width: 800px) {
            padding-bottom: 105%;
        }
	}

    .c-result-body & {
        transition: .65s cubic-bezier(0.815, 0.025, 0.235, 0.580);
    }

    &--compact {
        transform: translateY(-46%) translateX(37%);
        .c-quiz-last:not(.c-result-body) & {
            transform: translateX(37%) translateY(-100%);
        }
    }

    @media(min-width: 350px) {
        transform: translateX(30%) translateY(0);
        &--compact {
            transform: translateX(30%) translateY(-46%);
            .c-quiz-last:not(.c-result-body) & {
                transform: translateX(30%) translateY(-100%);
            }
        }
    }
    @media(min-width: 375px) {
        transform: translateX(30%) translateY(0);
        &--compact {
            transform: translateX(30%) translateY(-46%);
            .c-quiz-last:not(.c-result-body) & {
                transform: translateX(30%) translateY(-100%);
            }
        }
    }
    @media(min-width: 500px) {
        transform: translateX(25%) translateY(-12%);
        &--compact {
            transform: translateX(25%) translateY(-50%);
            .c-quiz-last:not(.c-result-body) & {
                transform: translateX(25%) translateY(-100%);
            }
        }
    }

    @media(min-width: 800px) {
        transform: none;

        &--compact {
            transform: translateY(-50%);
            .c-quiz-last:not(.c-result-body) & {
                transform: translateY(-100%);
            }
        }
    }

}

.c-holder__image {
    background-color: #000;
    height: 80px;
    width: 80px;
}

.c-grid__item {
    &--holder {
        position: absolute;
        right: 0;
        top: 0;
        max-width: 200px;
        width: 50%;
        overflow: hidden;
        @media(min-width: 800px) {
            position: relative;
            max-width: 502px;
            width: 100%;
            margin-top: -75px;
        }
    }
}