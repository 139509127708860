.loading-container {
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $primary-color;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loading {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    .loading-container {
        animation: fadeIn 0.5s 0.6s forwards;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}