.icecream,
.icecream__balls,
.cone {
	transform-origin: center bottom;
}
.icecream { 
    pointer-events: none; 
    position: absolute;
    bottom: -20px;
    left: 49%;
    transform: translateX(-70%) translateY(8%) scale(0.47);
    transition: .25s ease-out;
    .c-home-body & {
        transform: translateX(-70%) translateY(8%) scale(0.44);
    }
    .c-result-body & {
        transform: translateX(-70%) translateY(8%) scale(0.47);
    }
    .c-holder--compact & {
        transition: .25s .25s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }
    @media(min-width: 350px) {
        transform: translateX(-68%) translateY(8%) scale(0.46);
        .c-home-body & {
            transform: translateX(-68%) translateY(8%) scale(0.46);
        }
        .c-result-body & {
            transform: translateX(-68%) translateY(8%) scale(0.5);
        }
    }
    @media(min-width: 375px) {
        transform: translateX(-65%) translateY(8%) scale(0.47);
        .c-home-body & {
            transform: translateX(-65%) translateY(8%) scale(0.47);
        }
    }
    @media(min-width: 500px) {
        transform: translateX(-65%) translateY(8%) scale(0.55);
        .c-home-body & {
            transform: translateX(-65%) translateY(8%) scale(0.55);
        }
        .c-result-body & {
            transform: translateX(-65%) translateY(8%) scale(0.68);
        }
    }
    @media(min-width: 800px) {
        transform: translateX(-57%) translateY(8%) scale(0.67);
        .c-home-body & {
            transform: translateX(-57%) translateY(8%) scale(0.67);
        }
        .c-result-body & {
            transform: translateX(-57%) translateY(8%) scale(0.77);
        }
    }
    @media(min-width: 980px) {
        transform: translateX(-57%) translateY(8%) scale(0.82);
        .c-home-body & {
            transform: translateX(-57%) translateY(8%) scale(0.82);
        }
        .c-result-body & {
            transform: translateX(-57%) translateY(8%) scale(0.95);
        }
    }
}


.icecream__balls {
    max-width: 153px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column-reverse;
    top: -28px;
    left: 15%;
	img { 
		max-width: 100%; 
		display: block; 
		position: relative;
        z-index: 2;
        transition: 0.25s ease-in;
        margin-bottom: -32%;

        .c-holder--compact & {
            opacity: 0;
            transform: translateY(-80%);
        }

        .c-quiz-body & {
            opacity: 0;
            transform: translateY(-80%);
        }

        .c-quiz-last:not(.c-result-body) & {
            opacity: 0 !important;
            transform: translateY(-80%) !important;
        }
    }

    .ball-img-wrapper:nth-child(1) img {
        .c-result-body & {
            // animation: balls 0.25s 1s forwards;
        }
    }
    .ball-img-wrapper:nth-child(2) img {
        margin-left: -24%;
        margin-bottom: -40%;
        .c-result-body & {
            // animation: balls 0.25s 1.25s forwards;
        }
    } 
    .ball-img-wrapper:nth-child(3) img {
        margin-bottom: -45%;
        left: 14%;
        .c-result-body & {
            // animation: balls 0.25s 1.5s forwards;
        }
    } 
    .ball-img-wrapper:nth-child(4) img {
        left: -23%;
        margin-bottom: -43%;
    } 
    .ball-img-wrapper:nth-child(5) img {
        margin-bottom: -40%;
        left: 13%;
    } 
}

@keyframes balls {
    from { opacity: 0; transform: translateY(-10%); }
    to { opacity: 1; transform: translateY(0); }
}


.c-quiz-body {
    .icecream--first {
        bottom: calc(-20px + -3%);
        @media(min-width: 800px) {
            bottom: calc(-20px + -9%);
        }
        .icecream__balls {
            .ball-img-wrapper:nth-child(1) img {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }
    .icecream--second {
        bottom: calc(-20px + -8%);
        @media(min-width: 800px) {
            bottom: calc(-20px + -16%);
        }
        .icecream__balls {
            .ball-img-wrapper:nth-child(1) img, .ball-img-wrapper:nth-child(2) img {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }
    .icecream--third {
        bottom: calc(-20px + -13%);
        @media(min-width: 800px) {
            bottom: calc(-20px + -21%);
        }
        .icecream__balls {
            .ball-img-wrapper:nth-child(1) img, .ball-img-wrapper:nth-child(2) img, .ball-img-wrapper:nth-child(3) img {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }
    &.c-result-body .icecream {
        bottom: 0;
    }
}

.cone {
	display: block;
    width: 205px;
    top: 15px;
    left: -2px;
    transform: scale(1.04);
    position: relative;
}

.c-ball-flags {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    &--1 {
        top: 25%;
        left: 11%;
    }
    &--2 {
        top: 37%;
        left: -25%;
    }
    &--3 {
        top: 50%;
        left: -4%;
    }   
}

.c-home-flags {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.c-home-flag {
    width: 40%;
    position: absolute;
    opacity: 0;
    transition: 0.2s;
    .c-home-body & {
        opacity: 1;
    }
    &:nth-child(1) { left: 70%; top: 41%; }
    &:nth-child(2) { left: 8%; top: 27%; }
    &:nth-child(3) { left: 77%; top: 18%; }
    &:nth-child(4) { left: 44%; top: 9.6%; width: 25%; }
    &:nth-child(5) { left: 21%; top: -4%; width: 25%; }
}

.c-ball-flag {
    width: 35%;
    position: absolute;
    opacity: 0;
    transition: 0.4s 0.3s ease-in-out;
    &:nth-child(1) { left: 59%; top: -9%; z-index: 2; transform-origin: left bottom; transform: rotate(60deg); }
    &:nth-child(2) { left: 30%; top: -3.3%; z-index: 2; transform: rotate(-60deg); transform-origin: right bottom; }
    &:nth-child(3) { left: 65%; top: -9%;  top: -4%; transform: rotate(60deg); transform-origin: left bottom; z-index: 3; }
    &:nth-child(4) { left: 30%; top: -6.4%; transform: rotate(-60deg); transform-origin: right bottom; }
    &:nth-child(5) { left: 79%; top: -9%; transform: rotate(60deg); transform-origin: bottom left; }
    &:nth-child(6) { left: 14%; top: -9.3%; z-index: -1; transform: rotate(-60deg); transform-origin: right bottom; }
    .c-result-flags & {
        opacity: 1;
        &:nth-child(1) { left: 59%; top: -9%; z-index: 2; transform-origin: left bottom; transform: rotate(0deg); }
        &:nth-child(2) { left: 30%; top: -3.3%; z-index: 2; transform: rotate(-9deg); transform-origin: right bottom; }
        &:nth-child(3) { left: 65%; top: -9%;  top: -4%; transform: rotate(14deg); transform-origin: left bottom; z-index: 3; }
        &:nth-child(4) { left: 30%; top: -6.4%; transform: rotate(9deg); transform-origin: right bottom; }
        &:nth-child(5) { left: 79%; top: -9%; transform: rotate(22deg); transform-origin: bottom left; }
        &:nth-child(6) { left: 14%; top: -9.3%; z-index: -1; transform: rotate(-9deg); transform-origin: right bottom; }
    }
}

@media(min-width: 800px) {
    .old-browser {
        .ball-img-wrapper {
            padding-bottom: 53.9%;
            height: 0;
        }
    }
}