.c-home-wrap > .container {
    @media (min-width: $medium) {
        min-height: calc(100vh - 210px);
    }
}
.c-home-wrap > .container > .page {
    padding-top: 180px;
    @media (min-width: $medium) {
        padding-top: 120px;
    }
}
.c-home {
    display: flex;
    flex-direction: column;
    margin-top: -60px;
    @media (min-width: $large) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: -40px;
    }
    @media (min-width: $xxl) {
        margin-top: 0;
    }
    &__intro {
        font-size: 20px;
        max-width: 480px;
        color: $contrast;
        p:first-child {
            margin-top: 0.75rem;
        }
        @media(min-width: $medium) {
            font-size: 24px;
            flex: 0 0 50%;
            max-width: 680px;
            padding-right: 40px;
        }
    }
}
.c-home__title {
    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        font-size: 36px;
    }
    margin-top: 0.75rem;
    @media(min-width: $medium) {margin-top: 0;}
}
.c-home__loader {
    margin-top: -220px;
    order: -1;
    max-width: 214px;
    @media (min-width: $large) {
        order: 2;
        margin-top: -200px;
        max-width: 434px;
    }
    @media (min-width: $xl) {
        display: block;
        flex: 0 0 50%;
        position: absolute;
        top: 0;
        right: 0;
    }
    svg {
        width: 100%;
        height: auto;
    }

}