.c-about {
    select {
        border: 0;
        outline: none;
        cursor: pointer;
        padding: 0.8em 1em;
        background-color: #fff;
        box-shadow: 8px 6px 0 rgba(0, 0, 0, 0.1);
        transition: $transition;
        width: 220px;
        border-radius: 0;
        font-family: $font;
        &:hover {
            box-shadow: 6px 4px 0 rgba(0, 0, 0, 0.1);
        }
    }
    &__intro {
        margin-top: -1em;
        margin-bottom: 30px;
        font-size: 22px;
        color: $contrast;
        @media(min-width: $medium) {
            font-size: 28px;
            max-width: 680px;
            margin-bottom: 50px;
            margin-top: -0.5em;
        }
    }
    &__controls {
        margin-bottom: 30px;
        color: $contrast;
        &:first-child {
            margin-bottom: 60px;
        }
        @media (min-width: $large) {
            margin-bottom: 20px;
            select {
                width: 48%;
            }
        }
        h2 {
            margin-bottom: 10px;
        }
        
        @media (min-width: $large) {
            display: flex;
            align-items: center;
            .parts {
                flex: 0 0 50%;
            }
            h2.parts {
                flex: 0 0 22%;
                margin: 0;
            }
        }
    }
}
.c-radio-group {
    display: flex;
    align-items: center;
    margin: 0 -1em;
    @media (min-width: $large) {
        margin: 0 -4em;
    }
    label {
        margin: 0 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        @media (min-width: $large) {
            margin: 0 4em;
            flex-direction: row;
        }
        .faux-radio {
            display: block;
            width: 40px;
            height: 40px;
            background-color: #fff;
            position: relative;
            padding: 3px;
            margin-bottom: 10px;
            box-shadow: 8px 6px 0 rgba(0, 0, 0, 0.1);
            transition: $transition;
            @media (min-width: $large) {
                margin-right: 1em;
                margin-bottom: 0;
            }
        }
        &:hover {
            .faux-radio {
                box-shadow: 6px 4px 0 rgba(0, 0, 0, 0.1);
            }
        }
        input {
            opacity: 0;
            position: absolute;
        }
    }
}