.c-faq {
    margin-top: 40px;
    margin-bottom: 120px;
}

.c-faq__title {
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 1.75rem;
    margin-bottom: 0.6rem;
    line-height: 1.2;
    @media(min-width: 800px) {
        font-size: 1.7rem;
    }
    &.is-active {
        &:after {
            transform: rotate(-180deg);
        }
    }
    &:after {
        content: '';
        background-image: url('/media/images/ic-chevron-down.svg');
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 1.3em;
        height: 1.3em;
        vertical-align: middle;
        transition: 0.12s;
    }
}

.c-faq__item {
    margin-top: 30px;
}

.c-faq-item__answer {
    display: none;
    height: 0;
    transition: 0.12s;
    p:first-of-type {
        margin-top: 0;
    }
    &.is-active {
        height: auto;
        display: block;
    }
}